import styled from 'styled-components';
import { colors } from 'client/styles';

export const SelectBox = styled.div`
  position: relative;
  border-radius: 5px;

  svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    fill: ${colors.grey2};
  }
`;

export const Select = styled.button`
  appearance: none;
  border: 1px solid ${colors.grey4};
  border-radius: 5px;
  padding: 1rem;
  font-size: 1rem;
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  text-align: left;

  :focus {
    border-color: ${colors.grey1};
    outline: none;
  }
`;

export const AgesList = styled.ul`
  position: absolute;
  top: calc(100% + 5px);
  background-color: ${colors.white};
  width: 100%;
  border: 1px solid ${colors.grey4};
  border-radius: 8px;
  padding: 5px 0;
  z-index: 10;

  li {
    padding: 5px 10px;
    line-height: 1.3;
    cursor: pointer;

    :hover {
      background-color: ${colors.yellow2};
    }

    :focus {
      background-color: ${colors.yellow2};
      outline: none;
    }
  }
`;

export const AgeSelectorBase = styled.div`
  flex: 1 0 33.33%;
  max-width: calc(33.33% - 1rem);
  margin-top: 21px;

  :not(:nth-of-type(3n + 1)) {
    margin-left: 21px;
  }

  :nth-of-type(1),
  :nth-of-type(2),
  :nth-of-type(3) {
    margin-top: 0;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    `
    ${SelectBox} {
      box-shadow: 0px 0px 0px 1px ${colors.red};
    }

    ${Select} {
      border-color: ${colors.red};
    }
  `}
`;
