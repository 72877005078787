import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, InputNumber, Label, Icon } from './styles';

const Input = ({
  ariaAutocomplete = null,
  ariaExpanded = null,
  autoComplete = null,
  checked = false,
  className = null,
  disabled = null,
  icon = null,
  id = null,
  inputRef = null,
  label = null,
  name,
  onBlur = null,
  onChange = null,
  onFocus = null,
  onKeyDown = null,
  placeholder = null,
  readOnly = null,
  role = null,
  type,
  value = undefined,
}) => {
  const inputElement = (
    <InputNumber
      aria-autocomplete={ariaAutocomplete}
      aria-expanded={ariaExpanded}
      autoComplete={autoComplete}
      checked={checked}
      data-test={id}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={inputRef}
      role={role}
      type={type}
      value={value}
    />
  );

  return (
    <InputBase className={className}>
      {icon && <Icon>{icon}</Icon>}

      {label ? (
        <Label htmlFor={id}>
          {label}
          {inputElement}
        </Label>
      ) : (
        inputElement
      )}
    </InputBase>
  );
};

Input.propTypes = {
  ariaAutocomplete: PropTypes.string,
  ariaExpanded: PropTypes.string,
  autoComplete: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  role: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'number',
    'search',
    'email',
    'tel',
    'checkbox',
    'labeled',
    'password',
  ]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
