import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconAdd from 'client/icons/Add';
import IconRemove from 'client/icons/Remove';
import Input from '../Input';

import { IncrementSelectorBase, Title, Buttons } from './styles';
import { ButtonIconRound } from 'src/comps/Button/styles';

const IncrementSelector = ({
  maxLimit,
  minLimit,
  name,
  position = [0],
  title,
  type = 'default',
  update,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  function handleClickIncrement() {
    if (currentValue >= maxLimit) {
      return;
    }

    const updatedValue = currentValue + 1;
    setCurrentValue(updatedValue);
    update({ [name]: parseInt(updatedValue), position });
  }

  function handleClickDecrement() {
    if (currentValue <= minLimit) {
      return;
    }

    const updatedValue = currentValue - 1;
    setCurrentValue(updatedValue);
    update({ [name]: parseInt(updatedValue), position });
  }

  return (
    <IncrementSelectorBase type={type || 'default'}>
      <Title>{title}</Title>
      <Buttons>
        <ButtonIconRound
          type="button"
          onClick={handleClickDecrement}
          disabled={currentValue <= minLimit}
        >
          <IconRemove />
        </ButtonIconRound>

        <Input
          className="input-increment"
          name={`${name}[]`}
          readOnly
          type="number"
          value={currentValue}
        />

        <ButtonIconRound type="button" onClick={handleClickIncrement}>
          <IconAdd />
        </ButtonIconRound>
      </Buttons>
    </IncrementSelectorBase>
  );
};

IncrementSelector.propTypes = {
  maxLimit: PropTypes.number.isRequired,
  minLimit: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  type: PropTypes.string,
  update: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default IncrementSelector;
