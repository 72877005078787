import styled from 'styled-components';
import { colors, fonts } from 'client/styles';

export const InputNumber = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const InputBase = styled.div`
  border-radius: 3px;

  &.input-increment {
    align-items: center;
    display: flex;
    width: 52px;

    input[type='number'] {
      background-color: transparent;
      font-size: 1em;
      padding: 0;
      text-align: center;
    }
  }

  &.input-checkbox {
    input[type='checkbox'] {
      float: left;
    }

    label {
      width: 95%;
      text-align: left;
    }
  }
`;

export const Label = styled.label`
  display: inline-block;
  font-family: ${fonts.default};
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  width: 100%;
`;

export const Icon = styled.span`
  flex: 0 0 auto;
  text-align: center;
  width: 45px;
`;

export const InputLine = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px solid ${colors.grey4};
  line-height: normal; /* to avoid this error: https://stackoverflow.com/questions/8558603/ios-5-0-safari-not-vertically-centering-placeholder-in-text-box */
  padding: 1em;
  width: 100%;
`;
