import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import IconArrowDown from 'client/icons/ArrowDown';
import IconArrowUp from 'client/icons/ArrowUp';

import { AgeSelectorBase, SelectBox, Select, AgesList } from './styles';
import { Error } from 'client/components/FormErrorMessage/styles';
import messages from './messages';
import { useClickAway } from 'react-use';

const defaultAges = Array(18)
  .fill()
  .map((value, index) => `age-${index}`);

const AgeSelector = ({ initialAge, isValidationOn, item, position, titleTranslation, update }) => {
  const intl = useIntl();

  const ref = React.createRef();
  const [activeAge, setActiveAge] = useState();
  const [age, setAge] = useState(`${initialAge}` === '0' ? '0-1' : initialAge || '-');
  const [isOpen, setIsOpen] = useState(false);
  const [options] = useState(defaultAges);

  const isInvalid = isValidationOn && item === null;

  function onSelect(value) {
    setIsOpen(false);
    setAge(value === 0 ? '0-1' : value || '-');
    update(value);
  }

  function handleKeyDown(e, index) {
    if (e.key === 'Enter') {
      onSelect(index);
    }
  }

  const handleClose = () => setIsOpen(false);

  useClickAway(ref, handleClose);

  return (
    <AgeSelectorBase isInvalid={isInvalid} ref={ref}>
      <SelectBox>
        <Select
          data-testid="age-selector-btn"
          aria-label={intl.formatMessage(titleTranslation)}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-controls="ages"
          type="button"
          id={`children-ages-${position}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span data-testid="selected-age">{age}</span>
          {isOpen ? <IconArrowUp size={14} /> : <IconArrowDown size={14} />}
        </Select>

        {isOpen && (
          <AgesList
            role="menu"
            aria-labelledby={`children-ages-${position}`}
            id="ages"
            aria-activedescendant={activeAge}
          >
            {options.map((option, index) => (
              <li
                key={option}
                id={option}
                role="menuitem"
                onClick={() => onSelect(index)}
                onKeyDown={e => handleKeyDown(e, index)}
                onMouseEnter={() => setActiveAge(option)}
                onFocus={() => setActiveAge(option)}
              >
                {index === 0 && '0-1'}
                {index > 0 && index}
              </li>
            ))}
          </AgesList>
        )}
      </SelectBox>

      {isInvalid && (
        <Error>
          <FormattedMessage {...messages.ageError} />
        </Error>
      )}
    </AgeSelectorBase>
  );
};

AgeSelector.propTypes = {
  initialAge: PropTypes.number,
  isValidationOn: PropTypes.bool,
  item: PropTypes.number,
  position: PropTypes.string.isRequired,
  titleTranslation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }).isRequired,
  update: PropTypes.func.isRequired,
};

export default AgeSelector;
