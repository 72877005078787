import styled from 'styled-components';

export const IncrementSelectorBase = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${props => (props.type === 'fullscreen' ? '1em' : '0')};
`;

export const Title = styled.span`
  flex: 1 1 auto;
  text-align: left;
`;

export const Buttons = styled.div`
  display: flex;

  svg {
    fill: currentColor;
  }
`;
